var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.Lb_IsQSK)?_c('div',{staticClass:"d-flex"},[(_vm.Lb_IsQSK)?_c('v-btn',{staticClass:"ma-2 mr-4",attrs:{"dark":"","color":"success"},on:{"click":function($event){return _vm.getXlsReport(1)}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("general.ListOfQuestions"))+" ")],1):_vm._e(),_c('v-switch',{attrs:{"inset":"","label":"archivierte Fragen einblenden"},model:{value:(_vm.b_ShowArchived),callback:function ($$v) {_vm.b_ShowArchived=$$v},expression:"b_ShowArchived"}})],1):_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.routeToNewQuestion}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("general.new_question"))+" ")],1)],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("general.critical_incidents_questions"))+" "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"custom-filter":_vm.customSearch,"headers":_vm.headers,"items":_vm.a_QuestionsToDisplay,"item-key":"number","multi-sort":"","loading":_vm.Lb_Isloded,"loading-text":_vm.$t('general.please_wait'),"footer-props":{
          'items-per-page-text': _vm.$t('general.questions_per_page'),
        },"items-per-page":_vm.getCIQuestionsPerPage},on:{"update:items-per-page":_vm.GetOptions},scopedSlots:_vm._u([{key:"item.s_Designation",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(item.s_Designation)}})]}},{key:"item.s_De",fn:function(ref){
        var item = ref.item;
return [(!item.s_De && _vm.Lb_Translator)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.routeToEditQuestions(item.i_TestID, 1, 1, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"success":""}},[_vm._v("mdi-plus-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.add")))])])],1):(!item.s_De)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"danger","warning":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lock")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.not_available")))])])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.getEditionProps(item).color,"dark":"","icon":""},on:{"click":function($event){return _vm.routeToEditQuestions(item.i_TestID, 1, 0, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getEditionProps(item).label))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.getReport(item.i_TestID, 1, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]),(item.s_De && _vm.Lb_Translator)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#1565C0","dark":"","icon":""},on:{"click":function($event){return _vm.getDocxReport(item.i_TestID, 1, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-word-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Word")])]):_vm._e()],1)]}},{key:"item.s_Fr",fn:function(ref){
        var item = ref.item;
return [(!item.s_Fr && _vm.Lb_Translator)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.routeToEditQuestions(item.i_TestID, 2, 1, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"success":""}},[_vm._v("mdi-plus-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.add")))])])],1):(!item.s_Fr)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"danger","warning":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lock")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.not_available")))])])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.getEditionProps(item).color,"dark":"","icon":""},on:{"click":function($event){return _vm.routeToEditQuestions(item.i_TestID, 2, 0, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getEditionProps(item).label))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.getReport(item.i_TestID, 2, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]),(item.s_Fr && _vm.Lb_Translator)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#1565C0","dark":"","icon":""},on:{"click":function($event){return _vm.getDocxReport(item.i_TestID, 2, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-word-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Word")])]):_vm._e()],1)]}},{key:"item.s_It",fn:function(ref){
        var item = ref.item;
return [(!item.s_It && _vm.Lb_Translator)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.routeToEditQuestions(item.i_TestID, 3, 1, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"success":""}},[_vm._v("mdi-plus-box")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.add")))])])],1):(!item.s_It)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"danger","warning":""}},'v-icon',attrs,false),on),[_vm._v("mdi-lock")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.not_available")))])])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.getEditionProps(item).color,"dark":"","icon":""},on:{"click":function($event){return _vm.routeToEditQuestions(item.i_TestID, 3, 0, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getEditionProps(item).label))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.getReport(item.i_TestID, 3, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])]),(item.s_It && _vm.Lb_Translator)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#1565C0","dark":"","icon":""},on:{"click":function($event){return _vm.getDocxReport(item.i_TestID, 3, item.s_MainTestNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-word-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Word")])]):_vm._e()],1)]}},{key:"item.i_Released",fn:function(ref){
        var item = ref.item;
return [(item.i_Released == 1)?[_c('v-chip',{attrs:{"color":"white","label":"","small":"","text-color":"success"}},[_c('v-icon',[_vm._v(" mdi-check ")])],1)]:_vm._e()]}},{key:"item.s_Status",fn:function(ref){
        var item = ref.item;
return [(item.s_Status == 3)?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(_vm.$t("general.definitely"))+" ")],1)]:(item.s_Status == 0)?[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("general.draft"))+" ")])]:(item.s_Status == 1)?[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" QSK ")])]:(item.s_Status == 2)?[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("general.in_translation"))+" ")])]:_vm._e()]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.copyRow(item.i_TestID)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.copy")))])]),(item.i_Released == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.routeToQuestionsComments(item.i_TestID)}}},'v-btn',attrs,false),on),[(item.i_Has_Comment==0)?_c('v-icon',[_vm._v("mdi-comment-outline")]):_vm._e(),(item.i_Has_Comment==1)?_c('v-icon',[_vm._v("mdi-comment-alert")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.comments")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.routeToQuestionsHistory(item.i_TestID)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.protocol")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":"","disabled":item.b_QuestionBlocked},on:{"click":function($event){return _vm.deleteQuestionDialog(item.i_TestID)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])])],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("general.you_will_delete_a_question"))+"! ")]),_c('v-card-text',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t("general.would_you_like_to_continue"))+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.Lb_Deleting,"disabeld":_vm.Lb_Deleting,"color":"green darken-1","text":""},on:{"click":function($event){(_vm.dialog = false), (_vm.Li_QuestionIDToBeDeleted = null)}}},[_vm._v(" "+_vm._s(_vm.$t("general.no"))+" ")]),_c('v-btn',{attrs:{"loading":_vm.Lb_Deleting,"disabeld":_vm.Lb_Deleting,"color":"red darken-1","text":""},on:{"click":_vm.deleteQuestion}},[_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.Lb_GetDocDialog),callback:function ($$v) {_vm.Lb_GetDocDialog=$$v},expression:"Lb_GetDocDialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('general.please_wait'))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }